<template>
  <div>
    <b-badge
      pill
      :variant="pillVariant()"
      style="cursor: pointer;"
      @click="showDocumentation"
    >
      {{ pillText() }}
    </b-badge>

    <documentation-modal
      ref="documentationModal"
      :customer="customer"
    />
  </div>
</template>

<script>
import { ref } from '@vue/composition-api/dist/vue-composition-api'
import Model from '@/dc-it/models/Model'
import { BBadge } from 'bootstrap-vue'
import DocumentationModal from '@/views/components/modals/DocumentationModal.vue'

export default {
  name: 'DocumentationStatusComponent',
  components: { DocumentationModal, BBadge },
  props: {
    model: {
      type: Object,
      required: true,
    },
    value: {
      type: [Object, String],
      required: false,
      default: '',
    },
  },
  methods: {
    showDocumentation() {
      if (this.customer.getAttr('documentation_status', false).toString() === '1') {
        this.$refs.documentationModal.show()
      }
    },
  },
  setup(props) {
    const customer = ref(null)
    if (!props.model.getAttr) {
      const tmp = new Model()
      tmp.initValues(props.model)
      customer.value = tmp
    } else {
      customer.value = props.model
    }

    const pillVariant = () => {
      switch (customer.value.getAttr('documentation_status', false).toString()) {
        // 0=>pendiente, 1=>en revisión, 2=>aprobado, 3=>rechazado
        case '0':
          return 'warning'
        case '1':
          return 'info'
        case '2':
          return 'success'
        case '3':
          return 'danger'
        default:
          return 'primary'
      }
    }

    const pillText = () => {
      switch (customer.value.getAttr('documentation_status', false).toString()) {
        // 0=>pendiente, 1=>en revisión, 2=>aprobado, 3=>rechazado
        case '0':
          return 'Pendiente'
        case '1':
          return 'En revisión'
        case '2':
          return 'Aprobado'
        case '3':
          return 'Rechazado'
        default:
          return 'Estado desconocido'
      }
    }

    return {
      customer,
      pillVariant,
      pillText,
    }
  },
}
</script>

<style scoped>

</style>
