import { render, staticRenderFns } from "./AddressesView.vue?vue&type=template&id=5eb84b13&scoped=true&"
import script from "./AddressesView.vue?vue&type=script&lang=js&"
export * from "./AddressesView.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5eb84b13",
  null
  
)

export default component.exports