<template>
  <b-modal
    ref="refFormModal"
    title="Documentación"
    hide-footer
    no-close-on-backdrop
    size="lg"
  >
    <div class="container-fluid">
      <div
        v-if="loading"
        class="row"
      >
        <div class="col-12">
          <div
            class="d-flex align-items-center justify-content-center"
            style="min-height: 150px;"
          >
            <b-spinner

              class="mr-1"
              variant="primary"
            />
          </div>
        </div>
      </div>

      <div
        v-if="documentation"
        class="row"
      >
        <div

          class="col-12 col-md-4"
        >
          <a
            :href="documentation.getAttr('ine_front')"
            target="_blank"
            class="btn btn-secondary"
          >INE Frente</a>
        </div>
        <div class="col-12 col-md-4">
          <a
            :href="documentation.getAttr('ine_back')"
            target="_blank"
            class="btn btn-secondary"
          >INE Atrás</a>
        </div>
        <div class="col-12 col-md-4">
          <a
            :href="documentation.getAttr('proof_address')"
            target="_blank"
            class="btn btn-secondary"
          >Comprobante de domicilio</a>
        </div>
      </div>

      <div
        v-if="documentation"
        class="row"
      >
        <div class="col-12">
          <hr>

          <div>
            <div class="form-group">
              <label>Motivo de rechazo (solo si se rechaza la documentación)</label>
              <textarea
                v-model="reason"
                class="form-control"
                style="resize: none;"
              />
            </div>
          </div>

          <div class="btn-group">
            <button
              class="btn btn-success"
              @click="acceptData"
            >
              Validar información
            </button>
            <button
              class="btn btn-danger"
              @click="rejectData"
            >
              Rechazar información
            </button>
          </div>
        </div>
      </div>
    </div>
  </b-modal>
</template>
<script>

import { BSpinner } from 'bootstrap-vue'
import Documentation from '@/dc-it/models/Documentation'
import GeneralService from '@/dc-it/services/GeneralService'

export default {
  name: 'DocumentationModal',
  components: { BSpinner },
  props: {
    // eslint-disable-next-line vue/require-prop-types
    customer: {
      required: true,
    },
  },
  data() {
    return {
      loading: true,
      documentation: null,
      reason: '',
    }
  },
  mounted() {
    this.loadData()
  },
  methods: {
    show() {
      this.$refs.refFormModal.show()
    },
    rejectData() {
      if (this.reason.trim() === '') {
        alert('Debe ingresar el motivo de rechazo')
        return
      }

      GeneralService.getInstance().save('documentations/reject', { documentation_id: this.documentation.getAttr('id'), reject_reason: this.reason }).then(() => {
        this.$refs.refFormModal.hide()
        this.customer.setAttr('documentation_status', 3)
      })
    },
    acceptData() {
      GeneralService.getInstance().save('documentations/accept', { documentation_id: this.documentation.getAttr('id') }).then(() => {
        this.$refs.refFormModal.hide()
        this.customer.setAttr('documentation_status', 2)
      })
    },

    loadData() {
      this.loading = true
      Documentation.where('customer_id', '=', this.customer.getAttr('id')).where('status', '=', '1').get().then(documentation => {
        if (documentation.length > 0) {
          // eslint-disable-next-line prefer-destructuring
          this.documentation = documentation[0]
        }
        this.loading = false
      })
    },
  },
}
</script>

<style scoped>

</style>
