import User from '@/dc-it/models/User'
import BooleanField from '@/dc-it/types/BooleanField'
import DocumentationStatusComponent from '@/views/components/DocumentationStatusComponent.vue'
import Model from './Model'

import Field from '../types/Field'
import Relation from '../types/Relation'

export default class Customer extends Model {
    endpoint = 'customers';

    fields = [
      new Field('id', 'Código').disabled(),
      new Relation('user_id', 'Usuario', User, 'username').disabled().hide(),
      new Field('first_name', 'Nombre'),
      new Field('last_name', 'Apellido'),
      new Field('seller_name', 'Nombre visible en tienda').hide(),
      new Field('birthday', 'Fecha de nacimiento').hide(),
      new Field('phone', 'Teléfono'),
      new Field('alternative_phone', 'Teléfono alternativo'),
      new Field('email', 'Correo electrónico'),
      new Field('account_number', 'Número de cuenta').hide(),
      new Field('bank_name', 'Nombre de banco').hide(),
      new Field('status', 'Estado').hide(),
      new BooleanField('is_seller', 'Es vendedor').noFilterable(),
      new Field('ig_link', 'Perfil de instagram').noFilterable(),
      new Field('seller_status', 'Estado del vendedor').hide(),
      new Field('documentation_status', 'Estado de la documentación').renderComponent(DocumentationStatusComponent),
      new Field('lang', 'Lenguaje').hide(),

    ]

    clone = () => Customer;
}
