import City from './City'
import Model from './Model'

import Field from '../types/Field'
import Relation from '../types/Relation'
import Customer from './Customer'

export default class Address extends Model {
    endpoint = 'addresses';

    fields = [
      new Field('id', 'Código').disabled(),
      new Field('name', 'Nombre'),
      new Relation('customer_id', 'Cliente', Customer, 'name'),
      new Field('street', 'Calles'),
      new Field('ext_number', 'Numero externo'),
      new Field('int_number', 'Numero interno'),
      new Relation('city_id', 'Ciudad', City, 'name'),
      new Field('colony', 'Colonia'),
      new Field('references', 'Referencia'),
      new Field('zip_code', 'Código postal'),
      new Field('phone', 'Teléfono'),
      new Field('city', 'Ciudad'),
    ]

    clone = () => Address;
}
