import Model from './Model'
import Customer from './Customer'

import Field from '../types/Field'
import Select from '../types/Select'
import Relation from '../types/Relation'

export default class Documentation extends Model {
        endpoint = 'documentations';

        status = {
          rev: 'Revision',
          accepted: 'Aceptada',
          rejected: 'Rechazada',
        }

        fields = [
          new Field('id', 'Código').disabled(),
          new Field('ine_front', 'Parte delantera INE'),
          new Field('ine_back', 'Parte trasera INE'),
          new Field('proof_address', 'Dirección'),
          new Select('status', 'Estado', this.status),
          new Field('reject_reason', 'Razón de rechazo'),
          new Relation('customer_id', 'Cliente', Customer, 'first_name'),
        ]

    clone = () => Documentation;
}
